import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
const Form = () => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
  
    const handleSubmit = (event) => {
      event.preventDefault();
      if (!name || !phone) {
        alert('Please fill out both fields');
        return;
      }
      alert(`Name: ${name}\nPhone Number: ${phone}`);
    };
  
  return (
    <div className='contact_us_form mt-3 p-2'>
     
         <form onSubmit={handleSubmit}>
            <div className='d-flex justify-content-between' style={{gap:'5px'}}>

          <div className="mb-3 h-100 w-50">
            <label className="form-label">Start Date</label>
            <input
              type="date"
              className="form-control w-100"
               
             />
          </div>
          <div className="mb-3 h-100 w-50">
            <label className="form-label">End Date</label>
            <input
               type='date'
              
              className="form-control w-100"
            />
          </div>
          </div>

  <div className="mb-3">
            <label className="form-label d-flex justify-content-between"><span>Services</span> <i style={{color:'#6CA6D1'}} class="bi bi-plus-circle"></i> </label>
           <select className='w-100 form-control '>
            <option>Choose Services</option>
            <option>Choose Services</option>
            <option>Choose Services</option>
            <option>Choose Services</option>
            <option>Choose Services</option>
            
           </select>
          </div>

          <div className="mb-3">
            <label className="form-label">Description</label>
            <textarea
              type="text"
              className="form-control"
              placeholder="Your Name here"
              value={name}
              rows='3'
              
              onChange={(e) => setName(e.target.value)}
            ></textarea>
          </div>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="abc@xyz.com"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        
          <button type="submit" className="btn w-100" style={{background:"#6CA6D1",borderRadius:'10px',color:'white' }}>Request Quote</button>
        </form>
     </div>
  )
}

export default Form
