import React from "react";
import Accordion from "react-bootstrap/Accordion";

const Faqs = () => {
  return (
    <div>
      <h4 className="FAQS mt-3">Frequently Asked Questions</h4>
      <p>
        Find answers to some of the most common questions about our remodeling
        services.
      </p>

      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            How long does a typical remodeling project take?
          </Accordion.Header>
          <Accordion.Body>
            The duration of a remodeling project depends on the scope and
            complexity. For example, a bathroom remodel might take 2–3 weeks,
            while a full kitchen renovation could take 4–6 weeks. We'll provide
            a detailed timeline during your project consultation.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Do you offer free estimates?</Accordion.Header>
          <Accordion.Body>
            Yes, we offer free in-home estimates. Our team will assess your
            space, discuss your vision, and provide a detailed estimate to help
            you make an informed decision.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            What types of materials do you use?
          </Accordion.Header>
          <Accordion.Body>
            We work with high-quality materials from trusted suppliers. Whether
            you’re interested in luxury finishes or cost-effective options, we
            can recommend materials that fit your style and budget.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>
            Will I be able to stay in my home during the remodeling process?
          </Accordion.Header>
          <Accordion.Body>
            It depends on the extent of the remodel. For smaller projects, you
            can usually remain in your home with minimal disruption. However,
            larger projects like a full kitchen remodel may require temporary
            accommodations. We’ll work with you to minimize inconvenience.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Do you handle all necessary permits?
          </Accordion.Header>
          <Accordion.Body>
            Yes, we handle all required permits and inspections for your
            remodeling project. Our team ensures compliance with local
            regulations so you can have peace of mind.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            How do you ensure the project stays within budget?
          </Accordion.Header>
          <Accordion.Body>
            During the planning phase, we provide a detailed estimate based on
            your goals and budget. We maintain transparent communication
            throughout the project to avoid unexpected costs and adjust as
            needed with your approval.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            Can I make changes to the design during the remodeling process?
          </Accordion.Header>
          <Accordion.Body>
            We encourage all design changes to be finalized before the project
            begins. However, if you need to make adjustments, our team will work
            with you to accommodate them where possible, and we’ll discuss any
            potential impact on the budget and timeline.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            Do you offer warranties on your work?
          </Accordion.Header>
          <Accordion.Body>
            Yes, we stand behind our work with a warranty. We offer a one-year
            warranty on workmanship, and the manufacturers’ warranties cover
            materials. Our goal is to ensure your satisfaction and peace of mind
            long after the project is complete.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            What payment options do you offer?
          </Accordion.Header>
          <Accordion.Body>
            We accept various payment options, including credit cards, checks
            and cryptocurrency. We’ll discuss payment terms and schedules during
            your initial consultation.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>
            How do I get started with my remodeling project?
          </Accordion.Header>
          <Accordion.Body>
            Simply reach out to us via phone or our website to schedule a
            consultation. We’ll discuss your vision, answer any questions, and
            guide you through each step to bring your project to life.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default Faqs;
