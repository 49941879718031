import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'

const ContactUs = () => {
    return (
        <div>
            <h4 className='about_h4 mt-4'>Contact Us</h4>
            <p className='section3_p '>
                Get in touch with us today to discuss your project and discover how we can assist you.
            </p>
            <Container>
                <Row>
                    <Col xs='6' className='p-0 m-0'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19797537.72522052!2d-25.355979487193785!3d52.7197111206687!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x25a3b1142c791a9%3A0xc4f8a0433288257a!2sUnited%20Kingdom!5e0!3m2!1sen!2s!4v1722591190109!5m2!1sen!2s" style={{ width: '100%',height:'100%' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Col>
                    <Col xs='6'>
                        <div>
                            <h6 className='px-0 m-0'>Business hours</h6>
                             <div className='business mt-2'></div>

                            <Row>
                                <Col className='mt-1' xs='4'><h6 className='m-0 p-0 days' >Monday-</h6></Col>
                                <Col className='mt-1' xs='8'><p className='m-0 p-0 time text-center' >10:00 AM - 9:00 PM</p></Col>
                                <Col className='mt-1' xs='4'><h6 className='m-0 p-0 days' >Tuesday-</h6></Col>
                                <Col className='mt-1' xs='8'><p className='m-0 p-0 time text-center' >10:00 AM - 9:00 PM</p></Col>
                                <Col className='mt-1' xs='4'><h6 className='m-0 p-0 days-wed'  >Wednesday-</h6></Col>
                                <Col className='mt-1' xs='8'><p className='m-0 p-0 time text-center' >10:00 AM - 9:00 PM</p></Col>
                                <Col className='mt-1' xs='4'><h6 className='m-0 p-0 days' >Thursday-</h6></Col>
                                <Col className='mt-1' xs='8'><p className='m-0 p-0 time text-center' >10:00 AM - 9:00 PM</p></Col>
                                <Col className='mt-1' xs='4'><h6 className='m-0 p-0 days' >Friday-</h6></Col>
                                <Col className='mt-1' xs='8'><p className='m-0 p-0 time text-center' >10:00 AM - 9:00 PM</p></Col>
                                <Col className='mt-1' xs='4'><h6 className='m-0 p-0 days' >Saturday-</h6></Col>
                                <Col className='mt-1' xs='8'><p className='m-0 p-0 time text-center' >10:00 AM - 9:00 PM</p></Col>
                                <Col className='mt-1' xs='4'><h6 className='m-0 p-0 days' >Sunday-</h6></Col>
                                <Col className='mt-1' xs='8'><p className='m-0 p-0 days text-center' style={{  color:'red' }}>Closed</p></Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default ContactUs
