import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import frame26 from '../../assets/images/Rectangle26.svg'
const Product = () => {
    return (
        <div>
             <div className='section_2_our_client_bar mt-5'>
                <h4>Products We Offer</h4>
                <h5>See all</h5>
            </div>
            <p className='section3_p'>Meticulously crafted products showcasing our dedication to precision, durability, and timeless aesthetics.</p>
            <Container>
                <Row>
                    <Col xs='6'>
                        <div className='product_card p-2 my-1'>
                            <img src={frame26} className='w-100' />
                            <h4 className='m-0 mt-2 p-0 main_text_card4'>Premium Carpet</h4>
                            <div className='d-flex mt-2' style={{ alignItems: 'center', gap: '5px' }}>
                                <h5 className='m-0 p-0'>$1,200</h5>
                                <p className='m-0 p-0'>Per square feet</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs='6'>
                        <div className='product_card p-2 my-1'>
                            <img src={frame26} className='w-100' />
                            <h4 className='m-0 mt-2 p-0 main_text_card4'>Premium Carpet</h4>
                            <div className='d-flex mt-2' style={{ alignItems: 'center', gap: '5px' }}>
                                <h5 className='m-0 p-0'>$1,200</h5>
                                <p className='m-0 p-0'>Per square feet</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs='6'>
                        <div className='product_card p-2 my-1'>
                            <img src={frame26} className='w-100' />
                            <h4 className='m-0 mt-2 p-0 main_text_card4'>Premium Carpet</h4>
                            <div className='d-flex mt-2' style={{ alignItems: 'center', gap: '5px' }}>
                                <h5 className='m-0 p-0'>$1,200</h5>
                                <p className='m-0 p-0'>Per square feet</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs='6'>
                        <div className='product_card p-2 my-1'>
                            <img src={frame26} className='w-100' />
                            <h4 className='m-0 mt-2 p-0 main_text_card4'>Premium Carpet</h4>
                            <div className='d-flex mt-2' style={{ alignItems: 'center', gap: '5px' }}>
                                <h5 className='m-0 p-0'>$1,200</h5>
                                <p className='m-0 p-0'>Per square feet</p>
                            </div>
                        </div>
                    </Col> 

                </Row>
            </Container>
        </div>
    )
}

export default Product
