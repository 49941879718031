 import './App.css';
import Home from './pages/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
 
function App() {
  return (
    <div className="App"> 
        <Home/>
    </div>
  );
}

export default App;
