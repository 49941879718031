import React from 'react'
import Frame61 from '../../assets/images/Frame61.svg'
import Container from 'react-bootstrap/esm/Container'
const PreviousWork = () => {
  return (
    <div>
        <Container>

        <div className='section_2_our_client_bar mt-5'>
                <h4 className='about_h4'>Our Previous Works</h4>
                <h5>See all</h5>
            </div>
            <p className='section3_p'>Our portfolio showcases enduring designs that stand the test of time, both in style and function.</p>
            <img src={Frame61} className='w-100' />
        </Container>
    </div>
  )
}

export default PreviousWork
