import React from 'react'
import Container from 'react-bootstrap/esm/Container'

const AboutUs = () => {
    return (
        <div>
            <Container>

            <h4 className='about_h4 mt-4'>About Us</h4>
            <p className='section3_p '>
                Our mission is to transform your vision into stunning, functional spaces. See how we blend creativity and practicality to make your dream environment a reality.
            </p>
            </Container>
        </div>
    )
}

export default AboutUs
