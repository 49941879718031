import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';

import './style.css';
import frame26 from '../../assets/images/Frame26.svg'
import frame34 from '../../assets/images/Frame34.svg'
import frame21 from '../../assets/images/Frame21.svg'
import frame22 from '../../assets/images/Frame22.svg'
import frame23 from '../../assets/images/Frame23.svg'
import frame24 from '../../assets/images/Frame24.svg'
import frame28 from '../../assets/images/Frame28.svg'



import frame29 from '../../assets/images/Frame29.svg'

// import required modules
import { Scrollbar, Navigation } from 'swiper/modules';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Section3_swiper = () => {
    return (
        <div>
            <Swiper
                
                modules={[Navigation]}
                navigation={{
                    prevEl: '.prev_section_3',
                    nextEl: '.next_section_3',
                }}
                className="mySwiper_three"
            >
                <SwiperSlide className='w-100'>
                    <div class="card swiper_section2_cards">
                        <div class="card-body text-start">
                            {/* <h5 class="card-title">Special title treatment</h5> */}
                            <img src={frame34} />
                            <p class="card-text section3_swiper p-0 m-0 mt-1">Discover how we bring unique visions to life through our meticulously crafted interior designs.</p>
                            <div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>



                <SwiperSlide className='w-100'>
                    <div class="card swiper_section2_cards">
                        <div class="card-body text-start">
                            {/* <h5 class="card-title">Special title treatment</h5> */}
                            <img src={frame34} />
                            <p class="card-text section3_swiper p-0 m-0 mt-1">Discover how we bring unique visions to life through our meticulously crafted interior designs.</p>
                            <div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
            </Swiper>
            <Container>

                <div className='d-flex justify-content-between'>
                    <h4 style={{fontSize:'16px'}}>Carpeting Project 01</h4>
                    <div className='d-flex' style={{gap:'10px'}}>
                    <div className="prev_section_3"><img src={frame28} className='pre_btn' /></div> 
                    <div className="next_section_3"><img src={frame28} className='next_btn' /></div>
                    </div>
                </div>
            </Container>

        </div>
    );
};

export default Section3_swiper
