import React from 'react' 
import OffcanvasSection from './OffcanvasSection'
import logo from '../assets/images/logo2.svg'
const Navbarr = () => {
  return (
   <>
   <div className='navbar_section py-3'>
    <OffcanvasSection/>
    <h4 className='text-center'>Apex Carpet, Tile & Remodeling</h4>
    <img src={logo} />
   </div>

   </>
  )
}

export default Navbarr
