import React from 'react'
import Section4_swiper from './swiper_4'

const Section4 = () => {
    return (
        <div>
            <div className='section_2_our_client_bar mt-5'>
                <h4>Our Services</h4>
                <h5>See all</h5>
            </div>
            <p className='section3_p'>Discover how we bring unique visions to life through our meticulously crafted interior designs.</p>
            <Section4_swiper />
        </div>
    )
}

export default Section4
