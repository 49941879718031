import React from "react";
import SwiperComponent from "./Hero-section_swiper";
const Hero_section = () => {
  return (
    <div>
      <SwiperComponent />
      <h5 className="mt-5 hero_section_h4">
        Your Vision, Our Craftsmanship – Unmatched Remodeling, Tile, and Carpet
        Solutions
      </h5>
      <button className="hero_section_button py-2 px-4 mt-3">
        See all Services <i class="bi bi-arrow-right"></i>
      </button>
    </div>
  );
};

export default Hero_section;
