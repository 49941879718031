import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';

import './style.css';
import frame26 from '../../assets/images/Frame26.svg'
import frame20 from '../../assets/images/Frame20.svg'
import frame21 from '../../assets/images/Frame21.svg'
import frame22 from '../../assets/images/Frame22.svg'
import frame23 from '../../assets/images/Frame23.svg'
import frame24 from '../../assets/images/Frame24.svg'
import frame28 from '../../assets/images/Frame28.svg'



import frame29 from '../../assets/images/Frame29.svg'

// import required modules
import { Scrollbar, Navigation } from 'swiper/modules';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Swiper_section_2 = () => {


  return (
    <div>
      <Swiper

        modules={[Navigation]}
        navigation={{
          prevEl: '.prev',
          nextEl: '.next',
        }}
        className="mySwiper_two"
        style={{minHeight:'200px !important',height:'300px !important'}}
      >
        <SwiperSlide className='w-100'>
          <div class="card swiper_section2_cards">
            <div class="card-body text-start">
              {/* <h5 class="card-title">Special title treatment</h5> */}
              <p class="card-text section2_swiper">The installation process was seamless and efficient, and the quality of the carpet is outstanding. Our rooms feel cozier and more luxurious than ever.</p>
              <div>
                <Container>
                  <Row className='justify-content-between'>
                    <Col xs='6' className="p-0 m-0">
                      <div className='d-flex gap-sm-3 gap-2' > <img src={frame26} className='swiper_section2_img' /> <div className='card_flex_area'><h5 className='m-0 p-0'  >Don Norman</h5><p className='m-0 p-0' >CEO, NN group</p></div> </div>
                    </Col>
                    <Col xs='5' className="d-flex justify-content-end">
                      <img src={frame29} className='' style={{ objectFit: 'contain',maxWidth:'200px' }} />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className='w-100'>
          <div class="card swiper_section2_cards">
            <div class="card-body text-start">
              {/* <h5 class="card-title">Special title treatment</h5> */}
              <p class="card-text section2_swiper">The installation process was seamless and efficient, and the quality of the carpet is outstanding. Our rooms feel cozier and more luxurious than ever.</p>
              <div>
                <Container>
                  <Row className='justify-content-between'>
                    <Col xs='6' className="p-0 m-0">
                      <div className='d-flex gap-sm-3 gap-2'  > <img src={frame26} className='swiper_section2_img' /> <div className='card_flex_area'><h5 className='m-0 p-0' >Don Norman</h5><p className='m-0 p-0' style={{ fontSize: '10px' }}>CEO, NN group</p></div> </div>
                    </Col>
                    <Col xs='5' className="d-flex justify-content-end">
                      <img src={frame29} className='' style={{ objectFit: 'contain',maxWidth:'200px' }} />
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <Container>

      <div className='d-flex justify-content-around'>
      <div className="prev"><img src={frame28} className='pre_btn' /></div>
      <img src={frame26} className='swiper_section2_img' />
      <img src={frame21} className='swiper_section2_img' />
      <img src={frame22} className='swiper_section2_img' />
      <img src={frame23} className='swiper_section2_img' />
      <img src={frame24} className='swiper_section2_img' />
      <img src={frame26} className='swiper_section2_img' />


      <div className="next"><img src={frame28} className='next_btn' /></div>
      </div>
      </Container>

    </div>
  );
};

export default Swiper_section_2;
