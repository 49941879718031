import React from 'react'
import Section3_swiper from './section3_swiper'
import Container from 'react-bootstrap/esm/Container'

const Section_3 = () => {
  return (
    <div>
        <div className='section_2_our_client_bar mt-5'>
            <h4>Our Project</h4>
            <h5>See all</h5>
        </div>
        <p className='section3_p'>Discover how we bring unique visions to life through our meticulously crafted interior designs.</p>
      <Section3_swiper/>
    </div>
  )
}

export default Section_3
