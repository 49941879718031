import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const Contact = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!name || !phone) {
      alert('Please fill out both fields');
      return;
    }
    alert(`Name: ${name}\nPhone Number: ${phone}`);
  };

  return (
    <div className='contact_us_form mt-3 p-2'>
      <h5>Contact Me</h5>
      <Container>
        <form onSubmit={handleSubmit}>
          <div className="mb-3">
            <label className="form-label">Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Your Name here"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Contact NO.</label>
            <PhoneInput
              international
              placeholder="Enter phone number"
              value={phone}
              onChange={setPhone}
              className="w-100 py-2 px-2"
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Email</label>
            <input
              type="text"
              className="form-control"
              placeholder="abc@xyz.com"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="form-label">Massage</label>
            <textarea
              type="text"
              className="form-control"
              placeholder="Your Name here"
              value={name}
              rows='3'
              
              onChange={(e) => setName(e.target.value)}
            ></textarea>
          </div>
          <button type="submit" className="btn w-100" style={{background:"#6CA6D1",borderRadius:'10px',color:'white' }}>Submit</button>
        </form>
      </Container>
    </div>
  );
};

export default Contact;
