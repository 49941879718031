import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";

import "./style.css";
import frame26 from "../../assets/images/Rectangle26.svg";
// import required modules
import { Navigation } from "swiper/modules";

const Section4_swiper = () => {
  const services = [
    {
      title: "Full Kitchen Remodeling",
      description:
        "Revamp your culinary space with a modern, functional design that inspires.",
      startingPrice: "$5,000",
    },
    {
      title: "Bathroom Remodeling",
      description:
        "Upgrade to a luxurious bathroom with stylish fixtures and optimized space.",
      startingPrice: "$1,200",
    },
    {
      title: "Living Room Transformation",
      description:
        "Create a cozy, elegant space for relaxation and entertainment.",
      startingPrice: "$2,500",
    },
    {
      title: "Home Office Buildouts",
      description:
        "Design the perfect work-from-home environment tailored to productivity.",
      startingPrice: "$1,500",
    },
    {
      title: "Basement Finishing",
      description:
        "Maximize your home’s potential with a beautifully finished basement.",
      startingPrice: "$4,000",
    },
    {
      title: "Flooring Installation",
      description:
        "Choose from hardwood, vinyl, laminate, and tile for durable, stunning floors.",
      startingPrice: "$1,000",
    },
    {
      title: "Custom Tile Design & Installation",
      description:
        "Bring personality to your space with custom tile designs for floors, walls, or backsplashes.",
      startingPrice: "$800",
    },
    {
      title: "Carpet Installation",
      description: "Elevate comfort and style with premium carpet options.",
      startingPrice: "$1,200",
    },
    {
      title: "Outdoor Patios & Decking",
      description:
        "Extend your living space outdoors with beautifully crafted patios and decks.",
      startingPrice: "$3,000",
    },
    {
      title: "Interior Painting & Finishing",
      description:
        "Refresh your home’s interior with high-quality paint and finish options.",
      startingPrice: "$500",
    },
    {
      title: "Lighting Installation & Upgrade",
      description:
        "Brighten up your home with custom lighting solutions that enhance ambiance.",
      startingPrice: "$600",
    },
    {
      title: "Cabinetry & Storage Solutions",
      description:
        "Add elegance and functionality with custom cabinets and smart storage options.",
      startingPrice: "$1,200",
    },
  ];

  return (
    <div>
      <Swiper modules={[Navigation]} className="mySwiper_four">
        {services.map((service, index) => (
          <SwiperSlide className="w-75" key={index}>
            <div className="card swiper_section2_cards">
              <div className="card-body p-2 text-start">
                <img
                  src={frame26}
                  className="swiper_max_img"
                  alt={`${service.title} Image`}
                />
                <h5 className="main_text_card4 mt-2">{service.title}</h5>
                <p className="card-text section3_swiper p-0 m-0">
                  {service.description}
                </p>
                <h6 className="amount mt-2">
                  Starting from <span>{service.startingPrice}</span>
                </h6>
                <button className="request_btn_swiper_4 py-2">
                  Request quotes <i className="bi bi-arrow-right"></i>
                </button>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Section4_swiper;
