import React from 'react'
import Col from 'react-bootstrap/esm/Col'
import Container from 'react-bootstrap/esm/Container'
import Row from 'react-bootstrap/esm/Row'
import logo75 from '../../assets/images/logo75.svg'
import logo54 from '../../assets/images/logo54.svg'
import logo49 from '../../assets/images/logo49.svg'
import logo26 from '../../assets/images/logo26.svg'
import logo48 from '../../assets/images/logo48.svg'
import logo45 from '../../assets/images/logo45.svg'

const Companies = () => {
  return (
    <div className='mt-4'>
      <h4 className='text-center companies'>Companies We Represented</h4>
        <Container>
            <Row>
                <Col xs='4' className='p-1'><img src={logo75} className='h-100 w-100 border px-3 py-2' style={{borderRadius:'5px'}} /></Col>
                <Col xs='4' className='p-1'><img src={logo54} className='h-100 w-100 border px-3 py-2' style={{borderRadius:'5px'}} /></Col>
                <Col xs='4' className='p-1'><img src={logo49} className='h-100 w-100 border px-3 py-2' style={{borderRadius:'5px'}} /></Col>
                <Col xs='4' className='p-1'><img src={logo26} className='h-100 w-100 border px-3 py-2' style={{borderRadius:'5px'}} /></Col>
                <Col xs='4' className='p-1'><img src={logo48} className='h-100 w-100 border px-3 py-2' style={{borderRadius:'5px'}} /></Col>
                <Col xs='4' className='p-1'><img src={logo45} className='h-100 w-100 border px-3 py-2' style={{borderRadius:'5px'}} /></Col>

                
            </Row>
        </Container>
    </div>
  )
}

export default Companies
