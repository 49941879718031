import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

// import required modules
import { FreeMode, Navigation, Thumbs } from 'swiper/modules';

import Swiper_section_2 from './swiper_sectio_2/Swiper_section2'
const Section_2 = () => {
    

    return (
        <>
        <div className='section_2_our_client_bar mt-5'>
            <h4>Our Client’s Say</h4>
            <h5>See all</h5>
        </div>
        <Swiper_section_2 />
        </>
    );
}

export default Section_2
