import React from 'react';
import Navbarr from '../components/Navbar'
import Container from 'react-bootstrap/esm/Container'
import Hero_section from '../components/hero_section'

import Section_2 from '../components/Section_2'
import Section_3 from '../components/section3/section_3';
import Section4 from '../components/swiper4th/Section4';
import Product from '../components/section5_products/Product';
import Companies from '../components/section6/Companies';
import PreviousWork from '../components/section7/PreviousWork';
import AboutUs from '../components/AboutUs';
import OurMission from '../components/ourMission'
import ContactUs from '../components/ContactUs';
import Contact from '../components/Contact';
import Faqs from '../components/Faqs';
import Form from '../components/form';
import Footer from '../components/footer';
const Home = () => {
    return (
        <div>
            <Container>
                <Navbarr />
                <Hero_section/>
                <Section_2/>
                <Section_3/>
                <Section4/>
                <Product/>
                <Companies/>
                <PreviousWork />
                <AboutUs />
                <OurMission/>
                <ContactUs/>
                <Contact/>
                <Faqs/>
                <Form/>
            </Container>
                <Footer/>
        </div>
    )
}

export default Home
