import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import './hero_section_css/hero_section.css'
import image1 from '../assets/images/heroSectionImage.svg'
 
// import required modules
import { Pagination } from 'swiper/modules';

export default function App() {
  return (
    <>
      <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
        <SwiperSlide className='p-2'><img src={image1} /></SwiperSlide>
        <SwiperSlide className='p-2'><img src={image1} /></SwiperSlide>
        <SwiperSlide className='p-2'><img src={image1} /></SwiperSlide>
        <SwiperSlide className='p-2'><img src={image1} /></SwiperSlide>
        <SwiperSlide className='p-2'><img src={image1} /></SwiperSlide>
        <SwiperSlide className='p-2'><img src={image1} /></SwiperSlide>
        <SwiperSlide className='p-2'><img src={image1} /></SwiperSlide>
        <SwiperSlide className='p-2'><img src={image1} /></SwiperSlide>
        <SwiperSlide className='p-2'><img src={image1} /></SwiperSlide> 
      </Swiper>
    </>
  );
}
