import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./Css.css";
import { Pagination } from "swiper/modules";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import group from "../assets/images/Group.svg";
import Frame29 from "../assets/images/Frame29.svg";
import Frame26 from "../assets/images/Frame26.svg";

const ourMission = () => {
  const testimonials = [
    {
      text: "Project was completed under budget and in a timely manner! Mr. Inam and his team went above and beyond to accomodate our needs.",
      name: "Ahmer Malik",
      position: "Homeowner",
    },
    {
      text: "I had a major event coming up, and they re-tiled my hall in record time without compromising on quality. The results were fantastic, and my guests were impressed!",
      name: "Amit Patel",
      position: "Event Hall",
    },
    {
      text: "We are beyond thrilled with our newly remodeled kitchen! The team’s attention to detail and dedication to quality made all the difference.",
      name: "Sarah Johnson",
      position: "Homeowner",
    },
    {
      text: "Our bathroom remodel was seamless from start to finish. The team communicated every step of the way, and the results are simply stunning.",
      name: "Michael Lee",
      position: "Property Manager",
    },
    {
      text: "We had new hardwood floors installed throughout the house, and the quality of work is outstanding. The installation was quick and hassle-free.",
      name: "Linda Perez",
      position: "Interior Designer",
    },
    {
      text: "I was amazed at how efficient and professional the crew was. The entire basement was transformed into a family-friendly space in record time.",
      name: "David Thompson",
      position: "Homeowner",
    },
    {
      text: "The team handled everything from design to execution flawlessly. Our outdoor patio looks incredible, and it’s now our favorite place to entertain.",
      name: "Emma Williams",
      position: "Small Business Owner",
    },
    {
      text: "They turned our dated living room into a modern, cozy space that perfectly suits our style. The team was respectful of our home and left the place spotless every day.",
      name: "James Kim",
      position: "Architect",
    },
  ];

  return (
    <div className="w-100">
      <Container>
        <h4 className="mt-3 about_h4">Our Mission</h4>
        <Row>
          <Col xs="6" className="p-0">
            <div className="text-center m-1 p-2 mision_border_main">
              <img src={group} />
              <p className="transforming">
                Crafting Spaces, Elevating Experiences
              </p>
            </div>
          </Col>
          <Col xs="6" className="p-0">
            <div className="text-center m-1 p-2 mision_border_main">
              <img src={group} />
              <p className="transforming">
                Innovative Designs, Lasting Impressions
              </p>
            </div>
          </Col>
          <Col xs="6" className="p-0">
            <div className="text-center m-1 p-2 mision_border_main">
              <img src={group} />
              <p className="transforming">Turning Houses into Dream Homes</p>
            </div>
          </Col>
          <Col xs="6" className="p-0">
            <div className="text-center m-1 p-2 mision_border_main">
              <img src={group} />
              <p className="transforming">
                Quality Craftsmanship, Timeless Results
              </p>
            </div>
          </Col>
        </Row>
        <h4 className="mt-4 about_h4">Clients Say of Us</h4>
        <p className="section3_p ">
          Read the inspiring stories of our clients and see how we’ve helped
          turn their design dreams into reality.
        </p>

        <Swiper
          slidesPerView={3}
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          className="mySwiperrr"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index} className="width_45 border p-2">
              <div className="w-100">
                <img src={Frame29} style={{ width: "100px " }} />
                <p className="section3_swiper section_our_mission">
                  {testimonial.text}
                </p>
                <div className="d-flex gap-sm-3 gap-2">
                  <img src={Frame26} className="swiper_section2_img" />
                  <div className="card_flex_area">
                    <h5>{testimonial.name}</h5>
                    <p>{testimonial.position}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </Container>
    </div>
  );
};

export default ourMission;
